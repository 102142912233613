<template>
    <b-form-group
      :label="temLimiteSelecao ? 'Grupo (Selecione no máx 5)*' : 'Grupos'"
      label-for="grupo"
      label-class="font_size_label"
    >
      <validation-provider #default="{ errors }" name="Grupo" :rules="rules">
        <v-select
          id="grupo"
          :options="options"
          v-model="selected"
          :placeholder="placeholder"
          label="descricao"
          :clearable="true"
          :multiselect="ehMultiplo"
          :multiple="ehMultiplo"
          :disabled="loading"
          @input="checkMaxSelection"
        >
          <span slot="no-options">Nenhuma opção selecionável.</span>
        </v-select>
        <small v-if="errors[0]" class="text-danger">
          {{ errors[0] }}
        </small>
      </validation-provider>
    </b-form-group>
  </template>
  
  <script>
  import {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import vSelect from "vue-select";
  
  import {getUserData} from "@/auth/utils";
  import { TIPO_RELATORIO_ENUM } from "../config";
  
  export default {
    components: {
      BForm,
      BCol,
      BRow,
      BFormGroup,
      BFormInput,
      BButton,
      BTable,
      BSpinner,
      BFormDatepicker,
      BFormTextarea,
      BOverlay,
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      value: {
        required: true,
      },
      optionsConsulted: {
        required: false,
        type: Array,
        default: () => [],
      },
      idTipoRelatorio: {
        required: true,
        type: Number
      }
    },
    data() {
      return {
        options: [],
        userData: getUserData(),
        selected: this.value,
        placeholder: "Carregando...",
        loading: true,
        ehMultiplo: true,
        relatoriosComLimitacao: [
          TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID,
          TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_ID,
          TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_ID,
          TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID,
        ],
        temLimiteSelecao: false,
      };
    },
    beforeMount() {
      this.setOptions();
      this.setRules();
    },
    methods: {
      async setOptions() {
        if (this.optionsConsulted.length > 0) {
          this.options = this.optionsConsulted;
          return;
        }
  
        await this.$http
          .get(this.$api.getGruposCampanhaNacional())
          .then(({ data }) => {
            this.options = data.map((grupo) => {
  
              return {
                id_empresa: grupo.id_grupo,
                descricao: grupo.descricao,
              };
            });
          });
      },
      verificaRelatorioAdesaoPorEmpresasBaseNacional() {
        return this.idTipoRelatorio == TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID;
      },
  
      perfilSaudeDN() {
        const profiles = this.userData.perfis.map(profile => profile.id_perfil);
  
        return (profiles.includes(this.$perfil.SAUDE_DN));
      },
  
     verifyIfFildHasUfConcatenated() {
  
        if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID) {
            return this.perfilAdminDN();
        }
  
        if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_ID) {
            return this.perfilSaudeDN();
        }
  
        return false;
      },
      checkMaxSelection() {
        if (this.temLimiteSelecao && this.selected.length > 5) {
          this.selected.pop();
        }
      },

      setRules() {
        this.temLimiteSelecao = this.relatoriosComLimitacao.includes(this.idTipoRelatorio)
        if (this.temLimiteSelecao) {
          this.rules = "required"
        }
      },
      
    },
    watch: {
      selected: function (valor) {
        valor = this.ehMultiplo ? valor : [valor];
        this.$emit("selected", valor);
      },
      options: function (valor) {
        this.loading = this.options.length == 0;
        this.placeholder = this.loading
          ? "Nenhum registro encontrado"
          : "Selecione um Grupo.";
  
        this.$emit("options", valor);
      },
    },
  };
  </script>
  
  