export const ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_VISIBILIDADE = [
    'empresa',
    'unidadeOperacional',
    'cidade',
    'campanha',
    'realizadoPor',
    'statusDaAdesao',
    'periodoDataAdesao',
];

export const ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_SELECTED = [
    'empresaSelected',
    'unidadeOperacionalSelected',
    'cidadeSelected',
    'campanhaSelected',
    'realizadoPorSelected',
    'statusDaAdesaoSelected',
    'periodoDataAdesaoSelected',
];

export const EMPRESAS_CADASTRADAS_VISIBILIDADE = [
    'estado',
    'regiao',
    'cidade',
    'tipoDeEmpresa',
    'situacaoCadastro',
    'periodoDataCadastro',
];
export const EMPRESAS_CADASTRADAS_SELECTED = [
    'estadoSelected',
    'regiaoSelected',
    'cidadeSelected',
    'tipoDeEmpresaSelected',
    'situacaoCadastroSelected',
    'periodoDataCadastroSelected',
];

export const QUANTITATIVO_VACINACAO_VISIBILIDADE = [
    'empresa',
    'tipoDeEmpresa',
    'statusDaCampanha',
    'campanha',
    'vacina',
    'periodoDataVacinacao',
];

export const QUANTITATIVO_VACINACAO_SELECTED = [
    'empresaSelected',
    'tipoDeEmpresaSelected',
    'statusDaCampanhaSelected',
    'campanhaSelected',
    'vacinaSelected',
    'periodoDataVacinacaoSelected',
];

export const COLABORADORES_VACINADOS_VISIBILIDADE = [
    'empresa',
    'unidadeOperacional',
    'statusDaCampanha',
    'campanha',
    'categoriaDoColaborador',
    'vacina',
    'lote',
    'vacinador',
    'periodoDataVacinacao',
];

export const COLABORADORES_VACINADOS_VISIBILIDADE_ADMIN_EMPRESA = [
    'empresa',
    'unidadeOperacional',
    'statusDaCampanha',
    'campanha',
    'categoriaDoColaborador',
    'vacina',
    'periodoDataVacinacao',
];

export const COLABORADORES_VACINADOS_SELECTED = [
    'empresaSelected',
    'unidadeOperacionalSelected',
    'statusDaCampanhaSelected',
    'campanhaSelected',
    'categoriaDoColaboradorSelected',
    'vacinaSelected',
    'loteSelected',
    'vacinadorSelected',
    'periodoDataVacinacaoSelected',
];

export const ADESAO_POR_EMPRESAS_BASE_NACIONAL_VISIBILIDADE = [
    'drCoordenador',
    'drOperador',
    'grupo',
    'empresa',
    'unidadeOperacional',
    'cidade',
    'campanha',
    'periodoDataRealizacao',
];

export const ADESAO_POR_EMPRESAS_BASE_NACIONAL_SELECTED = [
    'drCoordenadorSelected',
    'drOperadorSelected',
    'grupoSelected',
    'empresaSelected',
    'unidadeOperacionalSelected',
    'cidadeSelected',
    'campanhaSelected',
    'periodoDataRealizacaoSelected',
];

export const AGENDAMENTOS_VISIBILIDADE = [
    'departamento',
    'empresa',
    'unidadeOperacional',
    'cidade',
    'campanha',
    'situacaoAgendamento',
    'periodoDataAgendamento',
];

export const AGENDAMENTOS_SELECTED = [
    'departamentoSelected',
    'empresaSelected',
    'unidadeOperacionalSelected',
    'cidadeSelected',
    'campanhaSelected',
    'situacaoAgendamentoSelected',
    'periodoDataAgendamentoSelected',
];

export const GRID_COLUNAS = {
    drCoordenador: 4,
    drOperador: 4,
    empresa: 4,
    estado: 4,
    regiao: 4,
    unidadeOperacional: 4,
    cidade: 4,
    tipoDeEmpresa: 4,
    statusDaCampanha: 4,
    situacaoCadastro: 4,
    campanha: 4,
    categoriaDoColaborador: 4,
    vacina: 4,
    lote: 4,
    vacinador: 4,
    realizadoPor: 4,
    statusDaAdesao: 4,
    periodoDataAdesao: 4,
    periodoDataCadastro: 4,
    periodoDataVacinacao: 4,
    periodoDataAdesaoNacional: 4,
};

export const TIPO_RELATORIO_ENUM = {
    EMPRESAS_CADASTRADAS_ID: 1,
    ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_ID: 2,
    QUANTITATIVO_VACINACAO_ID: 3,
    COLABORADORES_VACINADOS_ID: 4,
    ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID: 5,
    AGENDAMENTOS_ID: 6,

    EMPRESAS_CADASTRADAS_CHAVE: 'EMPRESAS_CADASTRADAS',
    ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_CHAVE: 'ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL',
    QUANTITATIVO_VACINACAO_CHAVE: 'QUANTITATIVO_VACINACAO',
    COLABORADORES_VACINADOS_CHAVE: 'COLABORADORES_VACINADOS',
    ADESAO_POR_EMPRESAS_BASE_NACIONAL_CHAVE: 'ADESAO_POR_EMPRESAS_BASE_NACIONAL',
    AGENDAMENTOS_CHAVE: 'AGENDAMENTOS',
}

export const TEXTO_PADRAO_SEM_FILTRO = {
    'NENHUM_FILTRO_SELECIONADO': [
        `<h5 class='btn-custom-blue'>Nenhum filtro selecionado</h5>`
    ],
    'EMPRESAS_CADASTRADAS': [
        '<h5 class="btn-custom-blue">Estado</h5>',
        '<h6>Todos os estados</h6>',
        '<h5 class="btn-custom-blue">Região</h5>',
        '<h6>Todas as regiões</h6>',
        '<h5 class="btn-custom-blue">Cidade</h5>',
        '<h6>Todas as cidades</h6>',
        '<h5 class="btn-custom-blue">Tipo de Empresa</h5>',
        '<h6>Todos os tipos</h6>',
        '<h5 class="btn-custom-blue">Situação de Cadastro</h5>',
        '<h6>Todas as situações</h6>',
        '<h5 class="btn-custom-blue">Período - Data de Cadastro</h5>',
        '<h6>Não Informado</h6>',
    ],
    'ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL': [
        '<h5 class="btn-custom-blue">Empresa</h5>',
        '<h6>Todas as empresas</h6>',
        '<h5 class="btn-custom-blue">Unidade Operacional</h5>',
        '<h6>Todas as unidades</h6>',
        '<h5 class="btn-custom-blue">Cidade</h5>',
        '<h6>Todas as cidades</h6>',
        '<h5 class="btn-custom-blue">Campanha</h5>',
        '<h6>Todas as campanhas</h6>',
        '<h5 class="btn-custom-blue">Realizado por</h5>',
        '<h6>Qualquer entidade</h6>',
        '<h5 class="btn-custom-blue">Status da Adesão</h5>',
        '<h6>Todos os status</h6>',
        '<h5 class="btn-custom-blue">Período - Data de Adesão</h5>',
        '<h6>Não Informado</h6>',
    ],
    'QUANTITATIVO_VACINACAO': [
        '<h5 class="btn-custom-blue">Empresa</h5>',
        '<h6>Todas as empresas</h6>',
        '<h5 class="btn-custom-blue">Tipo de Empresa</h5>',
        '<h6>Qualquer tipo de empresa</h6>',
        '<h5 class="btn-custom-blue">Status da Campanha</h5>',
        '<h6>Qualquer status de campanha</h6>',
        '<h5 class="btn-custom-blue">Campanha</h5>',
        '<h6>Todas as campanhas</h6>',
        '<h5 class="btn-custom-blue">Vacina</h5>',
        '<h6>Todas as vacinas</h6>',
        '<h5 class="btn-custom-blue">Período - Data de Vacinação</h5>',
        '<h6>Não Informado</h6>',
    ],
    'COLABORADORES_VACINADOS': [
        '<h5 class="btn-custom-blue">Empresa</h5>',
        '<h6>Todas as empresas</h6>',
        '<h5 class="btn-custom-blue">Unidade Operacional</h5>',
        '<h6>Todas as unidades</h6>',
        '<h5 class="btn-custom-blue">Status da Campanha</h5>',
        '<h6>Qualquer status de campanha</h6>',
        '<h5 class="btn-custom-blue">Campanha</h5>',
        '<h6>Todas as campanhas</h6>',
        '<h5 class="btn-custom-blue">Vacina</h5>',
        '<h6>Todas as vacinas</h6>',
        '<h5 class="btn-custom-blue">Lote/Fabricante</h5>',
        '<h6>Qualquer lote/fabricante</h6>',
        '<h5 class="btn-custom-blue">Vacinador</h5>',
        '<h6>Qualquer vacinador</h6>',
        '<h5 class="btn-custom-blue">Período - Data de Vacinação</h5>',
        '<h6>Não Informado</h6>',
    ],
    'ADESAO_POR_EMPRESAS_BASE_NACIONAL': [
        '<h5 class="btn-custom-blue">DR Coordenador</h5>',
        '<h6>Todos os DRs Coordenadores</h6>',
        '<h5 class="btn-custom-blue">DR Operador</h5>',
        '<h6>Todos os DRs Operadores</h6>',
        '<h5 class="btn-custom-blue">Grupo</h5>',
        '<h6>Todos os grupos</h6>',
        '<h5 class="btn-custom-blue">Empresa</h5>',
        '<h6>Todas as empresas</h6>',
        '<h5 class="btn-custom-blue">Unidade Operacional</h5>',
        '<h6>Todas as unidades</h6>',
        '<h5 class="btn-custom-blue">Cidade</h5>',
        '<h6>Todas as cidades</h6>',
        '<h5 class="btn-custom-blue">Campanha</h5>',
        '<h6>Todas as campanhas</h6>',
        '<h5 class="btn-custom-blue">Período de Realização</h5>',
        '<h6>Não especificado</h6>',
    ],
    'AGENDAMENTOS': [
        '<h5 class="btn-custom-blue">Empresa</h5>',
        '<h6>Todas as empresas</h6>',
        '<h5 class="btn-custom-blue">Unidade Operacional</h5>',
        '<h6>Todas as unidades</h6>',
        '<h5 class="btn-custom-blue">Cidade</h5>',
        '<h6>Todas as cidades</h6>',
        '<h5 class="btn-custom-blue">Situação do Agendamento</h5>',
        '<h6>Todas as situações</h6>',
        '<h5 class="btn-custom-blue">Campanha</h5>',
        '<h6>Todas as campanhas</h6>',
        '<h5 class="btn-custom-blue">Período - Data do Agendamento</h5>',
        '<h6>Não Informado</h6>',
    ],
}