<template>
    <b-form-group
      :label="rules != '' ? 'DR Operador*' : 'DR Operador'"
      label-for="drOperador"
      label-class="font_size_label"
    >
      <validation-provider #default="{ errors }" name="DR Operador" :rules="rules">
        <v-select
          id="drOperador"
          :options="options"
          v-model="selected"
          :placeholder="placeholder"
          label="descricao"
          :clearable="true"
          :multiselect="ehMultiplo"
          :multiple="ehMultiplo"
          :disabled="loading"
        >
          <span slot="no-options">Nenhuma opção selecionável.</span>
        </v-select>
        <small v-if="errors[0]" class="text-danger">
          {{ errors[0] }}
        </small>
      </validation-provider>
    </b-form-group>
  </template>
  
  <script>
  import {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import vSelect from "vue-select";
  
  import {getUserData} from "@/auth/utils";
  import { TIPO_RELATORIO_ENUM } from "../config";
  
  export default {
    components: {
      BForm,
      BCol,
      BRow,
      BFormGroup,
      BFormInput,
      BButton,
      BTable,
      BSpinner,
      BFormDatepicker,
      BFormTextarea,
      BOverlay,
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      value: {
        required: true,
      },
      optionsConsulted: {
        required: false,
        type: Array,
        default: () => [],
      },
      idTipoRelatorio: {
        required: true,
        type: Number
      }
    },
    data() {
      return {
        options: [],
        userData: getUserData(),
        selected: this.value,
        placeholder: "Carregando...",
        loading: true,
        parametros: {
          params: {
            paginacao: false,
            ordem: "nome_empresa",
          },
        },       
        rules: "",
        ehMultiplo: false,
      };
    },
    beforeMount() {
      this.setOptions();
      this.setRules();
    },
    methods: {
      async setOptions() {
        if (this.optionsConsulted.length > 0) {
          this.options = this.optionsConsulted;
          return;
        }
        await this.$http
        .get(this.$api.departamentosGrupoOperadorWithCampanha())
        .then(({ data }) => {
          this.options = data.map((departamento) => {

            return {
              id_departamento: departamento.id_departamento,
              descricao: departamento.descricao,
            };
          });
        });
      },
      setRules() {
        const ehAdesaoPorEmpresasBaseNacional = this.verificaRelatorioAdesaoPorEmpresasBaseNacional(),
          ehPerfilDN = this.verificaSeTemPerfilDN();

        if (ehAdesaoPorEmpresasBaseNacional && ehPerfilDN) {
          this.rules = "required"
          this.ehMultiplo = false
        }
      },
      verificaRelatorioAdesaoPorEmpresasBaseNacional() {
        return this.idTipoRelatorio == TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID;
      },
      verificaSeTemPerfilDN() {
        const idPerfisObrigatorios = [this.$perfil.SAUDE_DN, this.$perfil.ADMIN_DN];
        return idPerfisObrigatorios.includes(this.userData.perfis[0].id_perfil)
      }
    },
    watch: {
      selected: function (valor) {
        valor = this.ehMultiplo ? valor : [valor];
        this.$emit("selected", valor);
      },
      options: function (valor) {
        this.loading = this.options.length == 0;
        this.placeholder = this.loading
          ? "Nenhum registro encontrado"
          : "Selecione um Operador.";
  
        this.$emit("options", valor);
      },
    },
  };
  </script>
  
  